body {
  margin: 0;
  background-color: #1C1C20;
  color: #E6E8F4;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
$font-primary: "Montserrat", sans-serif;

.App {
  width: 100%;
  height: 100vh;
  font-family: Montserrat;
}

a {
  text-decoration: none;
}

.Landing {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &.hl_1 {
    background-image: url('../public/images/bg-sport.jpg');
  }
  &.hl_2 {
    background-image: url('../public/images/bg-movie.jpg');
  }
  &.hl_3 {
    background-image: url('../public/images/bg-anime.jpg');
  }
  &.hl_4 {
    background-image: url('../public/images/bg-show.jpg');
  }
  &.hl_5 {
    background-image: url('../public/images/bg-18.jpg');
  }
  &.hl_6 {
    background-image: url('../public/images/bg-manga.jpg');
  }
  &_Header {
    // width: calc(29% - 80px);
    width: fit-content;
    height: 52px;
    background-color: #2D2E33;
    border-radius: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    overflow: hidden;
    margin-top: 32px;
    margin-bottom: 24px;

    &_Text {
      font-size: 18px;
      font-weight: 800;
      color: #fff;
      width: fit-content;
      font-family: $font-primary;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_Page {
    &_Tab {
      cursor: pointer;
      width: 58px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_Tab:hover {
      background-color: rgb(107, 74, 228, 0.3);
      transition: 0.3s;
      color: #fff;
    }
  }

  &_Body {
    // margin: 0px 100px 89px 100px;
    max-width: 1600px;
    padding: 0 15px 110px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 20px;
      right: 0;
      width: 330px;
      height: 300px;
      background: url('../public/images/Popcorn.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }

    &_Information {
      padding-right: 280px;
    }

    &_Tablist {
      display: flex;
      align-items: stretch;
      justify-content: center;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      &_ItemStatic {
        position: relative;
        cursor: pointer;
        flex: 1;

        &_Popcorn img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 110%;
        }
      }

      &_Item {
        flex: 1;

        & img {
          object-fit: contain;
          max-width: 100%;
        }

        flex-shrink: 0;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: 0.3s;
        height: 100%;
        background: #2D2E33;

        &_Image {
          position: relative;
          display: flex;

          &::before {
            content: '';
            background: url('../public/images/before-tab.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            height: 27px;
            bottom: 20px;
            left: 15px;
            right: 15px;
            z-index: 11;
          }

          &::after {
            content: "";
            background: linear-gradient(180deg, rgba(255, 189, 120, 0.00) 0%, #FFBD78 100%);
            position: absolute;
            width: 100%;
            height: 74px;
            bottom: 0;
            left: 0;
          }

          &_Inner {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transition: 0.3s;
          }

          &.tab1 {
            &::after {
              background: linear-gradient(180deg, rgba(85, 233, 154, 0.00) 0%, #55E99A 100%);
            }

            ~.Landing_Body_Tablist_Item_Text {
              &::before {
                border-bottom-color: #55E99A;
              }
            }
          }

          &.tab2 {
            &::after {
              background: linear-gradient(180deg, rgba(103, 202, 254, 0.00) 0%, #67CAFE 100%);
            }

            ~.Landing_Body_Tablist_Item_Text {
              &::before {
                border-bottom-color: #67CAFE;
              }
            }
          }

          &.tab3 {
            &::after {
              background: linear-gradient(180deg, rgba(254, 136, 129, 0.00) 0%, #FE8881 100%);
            }

            ~.Landing_Body_Tablist_Item_Text {
              &::before {
                border-bottom-color: #FE8881;
              }
            }
          }

          &.tab5 {
            &::after {
              background: linear-gradient(180deg, rgba(192, 169, 255, 0.00) 0%, #C0A9FF 100%);
            }

            ~.Landing_Body_Tablist_Item_Text {
              &::before {
                border-bottom-color: #C0A9FF;
              }
            }
          }
        }

        &_Text {
          background: #2D2E33;
          display: grid;
          grid-template-columns: auto auto;
          justify-content: space-between;
          align-items: flex-end;
          padding: 32px 16px 28px;
          gap: 12px;
          position: relative;

          &::before {
            content: '';
            border-bottom: 7px solid #FFBD78;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            width: 44px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0) rotate(180deg);
            top: -1px;
          }

          &_Left {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &_Title {
              color: #fff;
              font-family: Montserrat;
              text-transform: uppercase;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              /* 140% */
            }

            &_Description {
              color: #E5E6EB;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              /* 142.857% */
            }
          }

          &_Right {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            & img {
              height: 44px;
              width: 44px;
            }
          }
        }

        &:hover {
          transition: 0.3s;
          transform: scale(1.05);

          .Landing_Body_Tablist_Item_Image_Inner {
            transform: scale(1.1);
          }
        }
      }
    }

    &_TablistSelected {
      max-width: 950px;
      margin: 0 auto;

      &_Item {
        order: -1;
        margin-right: 20px;
        max-width: 310px;

        & img {
          width: 100%;
        }

        flex-shrink: 0;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &_Text {
          background-color: #2D2E33;
          padding: 32px 16px 28px;
          display: flex;
          gap: 12px;
          align-items: center;

          &_Left {
            display: flex;
            flex-direction: column;

            &_Title {
              color: #fff;
              font-family: Montserrat;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              /* 140% */
            }

            &_Description {
              color: #E5E6EB;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              /* 142.857% */
            }
          }

          &_Right {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            & img {
              height: 44px;
              width: 44px;
            }
          }
        }

        &_Image {
          position: relative;
          display: flex;
          overflow: hidden;

          &_Inner {
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
          }
        }
      }

      &_ItemPage2 {
        display: flex;

        &_Left {
          flex: 5;
          padding: 20px 25px;
          background: #2D2E33;
          z-index: 1;

          &_Title {
            display: flex;
            gap: 16px;
            align-items: center;
            color: #33be9f;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 32px;
            /* 133.333% */
            text-transform: capitalize;
          }

          &_Content {
            margin-top: 28px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &_Head {
              color: #fff;
              font-family: Montserrat;
              font-size: 18px;
              font-weight: 700;
              line-height: 24px;
            }

            &_Description {
              color: #E5E6EB;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;

              & span {
                color: #fff;
                font-weight: 600;
                line-height: 24px;
              }
            }

            &_SubInfo {
              display: flex;
              align-items: center;
              justify-content: space-between;
              align-items: center;
              gap: 40px;
              margin: 15px 0 0;
              + .Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo {
                margin: 8px 0 0px;
                padding-top: 16px;
                border-top: 1px solid #606060;
              }
              &_Wrapper {
                display: flex;
                gap: 30px;
                flex: 1;
                justify-content: space-between;
              }
              &_Left {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 12px;

                &_Head {
                  color: #E5E6EB;
                  font-family: Montserrat;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  /* 150% */
                  &_Icon {
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    img {
                      width: 36px;
                      height: 36px;
                      border-radius: 50%;
                      object-fit: contain;
                    }
                  }
                }

                &_Content {
                  color: #E5E6EB;
                  font-family: Montserrat;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  /* 142.857% */
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  span {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }

            &_Note {
              color: #777;
              font-family: Montserrat;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              text-align: center;
            }

            &_Button {
              text-decoration: none;
              color: #fff;
              display: flex;
              padding: 6px 15px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 29px;
              background: #6b4ae4;
              color: var(--Neutral-06, #fff);
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              /* 150% */
              text-transform: uppercase;
            }
          }

          &_Image {
            display: flex;
            gap: 14px;

            a {
              display: flex;
            }
          }
        }

        &_Right {
          position: relative;
          background: #2D2E33;
          display: flex;
          align-items: center;
          padding: 20px;
          padding-left: 10px;
          flex: 4;
          justify-content: center;

          img {
            max-width: 100%;
          }

          &_Icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }
    }

    &_Information {
      &_Title {
        margin-top: 48px;
        color: #fff;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 52px;
        /* 130% */
        text-transform: uppercase;
      }

      &_Content {
        margin-top: 14px;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
      }

      &_Guarantee {
        margin-top: 24px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 11px;

        &_Box {
          display: flex;
          width: fit-content;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 200px;
          background: #2D2E33;
          flex-wrap: nowrap;
        }
      }
    }
  }
}

.Landing_Body_TablistSelected_ItemPage2_Left_Title {
  margin: 0 0 25px;
}

.Landing_Body_TablistSelected_ItemPage2_Left_Step {
  margin: 0 0 20px;

  &_Content {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    font-weight: 500;

    &_Number {
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(107, 74, 228, 0.40);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }

    span {
      font-weight: 700;
    }
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(200%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 0.2s ease-out forwards;
  z-index: 11;
}

.fade-in {
  animation: fadeIn 0.5s;
}

.d-none {
  display: none !important;
}

.Landing_Body_Tablist_ItemStatic {
  flex: 48% 1;
  max-width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Responsive
@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }

  .d-xl-none {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .Landing_Body {
    max-width: 100%;
  }

  .Landing_Body_Tablist {
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    &_Item {
      >img {
        width: 100%;
        height: auto;
      }
    }
  }

  .Landing_Body_Tablist_Item_Image>img {
    width: 100%;
    max-width: 100%;
  }

  .Landing_Body_Information_Title {
    font-size: 24px;
    line-height: 32px;
  }

  .Landing_Body_TablistSelected_ItemPage2 {
    flex-wrap: wrap;
  }

  .Landing_Body_TablistSelected_ItemPage2_Left {
    padding: 12px;
    padding-bottom: 24px;
    width: 100%;
    flex: 100%;
  }

  .Landing_Body_TablistSelected_ItemPage2_Right {
    padding: 0;
    padding-left: 4%;
    flex: 46%;
    max-width: 46%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .Landing_Body_TablistSelected_Item,
  .Landing_Body_TablistSelected_ItemStatic {
    margin: 0;
    order: unset;
    flex: 50%;
    max-width: 50%;

    >img {
      max-width: 100%;
      height: 100%;
    }
  }

  .Landing_Body_TablistSelected_ItemStatic {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .Landing_Body_TablistSelected_Group_Mobile {
    display: flex;
  }

  .Landing_Body_Information {
    padding-right: 0;
  }

  .Landing_Body:after {
    display: none;
  }
}

@media (max-width: 991px) {
  .Landing_Body_Tablist_Item_Text_Left_Title {
    line-height: 24px;
  }

  .Landing_Body_Tablist_Item_Text {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto;
  }


}

@media (max-width: 767px) {
  .Landing_Header {
    padding: 0 25px;
  }

  .Landing_Header_Text {
    font-size: 16px;
  }

  .Landing_Body_Tablist {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
  }

  .Landing_Body_Tablist_Item,
  .Landing_Body_Tablist_ItemStatic {
    max-width: 100%;
  }

  .Landing_Body_Tablist_Item_Text {
    padding: 20px 12px;
  }

  .Landing_Body_Tablist_Item_Text_Left_Title {
    font-size: 16px;
    line-height: 20px;
  }
  .Landing_Body_Tablist_Item_Text_Right img,
  .Landing_Body_TablistSelected_Item_Text_Right img {
    width: 34px;
    height: 34px;
  }

  .Landing_Body_TablistSelected_ItemPage2_Left {
    margin-bottom: 16px;
  }

  .Landing_Body_TablistSelected_Item_Text {
    display: none;

    .Landing_Body_TablistSelected_Item_Text_Left_Title {
      font-size: 16px;
    }

    .Landing_Body_TablistSelected_Item_Text_Left_Description {
      display: none;
    }
  }

  .Landing_Body_TablistSelected_Item_Image {
    height: 100%;
  }

  .Landing_Body_TablistSelected_ItemPage2_Right {
    background: transparent;
  }

  .Landing_Body_Tablist_ItemStatic {
    display: block;
  }

  .Landing_Body_TablistSelected_ItemPage2_Left_Title {
    flex-direction: column;
  }

  .Landing_Body_Tablist_ItemStatic_Image {
    height: 100%;

    img {
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
  .Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo {
    gap: unset;
  }
  .Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo_Wrapper {
    gap: 0;
    flex-direction: column;
  }

  .Landing_Body_TablistSelected_Item {
    flex: 1;
    max-width: 100%;
  }

  .Landing_Body_TablistSelected_ItemStatic {
    display: none;
  }
}

.Landing_Body_TablistSelected_ItemPage2_Left_Content_SubInfo_Right {
  cursor: pointer;
  img {
    width: 24px;
  }
}

@keyframes animate1 {
  from {
    background-position: 0% 50%;
  }

  to {
    background-position: 100% 50%;
  }
}

.highlight {
  background: #2D2E33;
  border: 3px solid transparent;
  border-radius: 5px;
  position: relative;
  transition: 0.5s;

  &.active::after {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, #F4A889, #E77670, #B63565, #5073b8, #1098ad, #6fba82);
    height: calc(100% + 3px * 4);
    width: calc(100% + 3px * 4);
    top: calc(-3px * 2);
    left: calc(-3px * 2);
    z-index: -1;
    border-radius: 5px;
    background-size: 200%;
    animation: animate1 1s ease infinite alternate;
  }
}

.telegram-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.telegram-btn a {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #1E88E5;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 24px;
  font-weight: 500;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.telegram-btn a:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
}

.telegram-btn svg {
  width: 24px;
  height: 24px;
}